<template>
  <div v-images-loaded:on.done='imageMapSizeWatch'>
    <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/sicem2021/sponsors/enm_booth.jpg' usemap="#enm" class='' style='width:100vw;max-width:100vw; margin-left: calc(50% - 50vw);'>
    <map name="enm">
      <area target="_blank" alt="pdf" title="pdf" href="https://d3r2ol85dktaw0.cloudfront.net/conferences/sicem2021/sponsors/sponsor_details/ENM_CF_2.PDF" coords="416,485,648,643" shape="rect">
      <area target="_blank" alt="enm" title="enm" href="https://www.e-enm.org/" coords="260,161,819,405" shape="rect">
      <area target="_blank" alt="Submisssion" title="Submisssion" href="https://submit.e-enm.org/" coords="728,477,845,713" shape="rect">
      <area target="_blank" alt="4.010" title="4.010" href="https://mjl.clarivate.com/cgi-bin/jrnlst/jlresults.cgi?PC=MASTER&amp;ISSN=2093-596X" coords="893,475,1023,717" shape="rect">
      <area target="_blank" alt="Audio Summary" title="Audio Summary" href="https://www.e-enm.org/articles/audio_summary.php" coords="1063,470,1196,714" shape="rect">
      <area target="_blank" alt="Audio Slides" title="Audio Slides" href="https://www.e-enm.org/articles/audioslides.php" coords="1350,548,1594,692" shape="rect">
    </map>
  </div>
</template>

<script>
import imagesLoaded from 'vue-images-loaded'
import ImageMap     from 'image-map'

export default {
  name: 'EnM',
  directives: {
    imagesLoaded
  },
  methods: {
    imageMapSizeWatch () {
      ImageMap('img[usemap]')
    },
  },
}
</script>
